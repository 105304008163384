import React, { SyntheticEvent, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useDispatch, useSelector } from 'react-redux';
import CountrySelect from '../../components/CountrySelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  achCountryList,
  bankWireRestrictedCodes,
  prepaidCardAvailableCountries,
  pushToCardAvailableCountries,
} from '../../constants/countries';
import {
  fetchPrepaidCardPlan,
  setHomeStep,
  setHomeUserData,
  setPrepaidCardModal,
} from '../../store/system/actions';
import { selectHomeEdit, selectHomeUserData } from '../../store/system/selectors';
import CustomButtons from '../../components/CustomButtons';
import PrepaidCardModal from '@root/components/PrepaidCardModal';

export default function FirstStep() {
  const dispatch = useDispatch();
  const canEdit = useSelector(selectHomeEdit);
  const user = useSelector(selectHomeUserData);
  const userData = { method: user?.method || '', countryCode: user?.countryCode };
  const code = userData.countryCode || '';
  const achOnly = achCountryList.includes(code);
  const bankWireAllowed = !achOnly && !bankWireRestrictedCodes.includes(code);

  const prepaidCardAllowed =
    (user?.prepaidCards?.physical_cards || 0 < 3) && prepaidCardAvailableCountries.includes(code);

  const pushToCardAllowed = false; //pushToCardAvailableCountries.includes(code);

  const setUserData = (data: { method?: string | undefined | null; countryCode?: string }) => {
    dispatch(setHomeUserData(data));
  };

  const renderButtons = () => (canEdit ? <CustomButtons /> : <div />);

  const onCountryChange = (val: string) => {
    const clearMethod =
      (achCountryList.includes(code) && !achCountryList.includes(val)) ||
      (!achCountryList.includes(code) && achCountryList.includes(val)) ||
      (!bankWireRestrictedCodes.includes(code) && bankWireRestrictedCodes.includes(val)) ||
      (pushToCardAvailableCountries.includes(code) && !pushToCardAvailableCountries.includes(val));

    const nextMethod = clearMethod ? { method: null } : {};
    setUserData({ ...userData, countryCode: val, ...nextMethod });
  };

  const isDisabled = !canEdit;

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userData.method === 'prepaidCard') {
      dispatch(setPrepaidCardModal(true));
      return;
    }
    dispatch(setHomeStep(1));
  };

  useEffect(() => {
    if (userData.method === 'prepaidCard' && !prepaidCardAllowed) {
      setUserData({
        ...userData,
        method: '',
      });
    }
  }, [userData.method, prepaidCardAllowed]);

  useEffect(() => {
    if (user?.method === 'prepaidCard' && code) {
      dispatch(fetchPrepaidCardPlan(code));
    }
  }, [code, user?.method]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} direction={'column'}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Destination Country
          </Typography>
        </Grid>
        <Grid item xs={8} md={6}>
          <CountrySelect
            reinitialize
            disabled={isDisabled}
            id={'countries'}
            value={userData.countryCode}
            onChange={onCountryChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Payment Methods
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={userData.method}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setUserData({
                ...userData,
                method: e.target.value as 'ACH' | 'PayPal',
              })
            }
          >
            {achOnly && (
              <FormControlLabel
                disabled={isDisabled}
                value="ACH"
                control={<Radio color="primary" />}
                label="ACH"
              />
            )}

            {bankWireAllowed && (
              <FormControlLabel
                value="bankWire"
                disabled={isDisabled}
                control={<Radio color="primary" />}
                label="Bank Wire"
              />
            )}

            <FormControlLabel
              value="PayPal"
              disabled={isDisabled}
              control={<Radio color="primary" />}
              label="PayPal"
            />

            {/*<FormControlLabel*/}
            {/*  value='payoneer'*/}
            {/*  disabled={isDisabled}*/}
            {/*  control={<Radio color='primary' />}*/}
            {/*  label='Payoneer'*/}
            {/*/>*/}

            {/*{prepaidCardAllowed && (*/}
            {/*  <FormControlLabel*/}
            {/*    value="prepaidCard"*/}
            {/*    disabled={isDisabled}*/}
            {/*    control={<Radio color="primary" />}*/}
            {/*    label="Prepaid card"*/}
            {/*  />*/}
            {/*)}*/}

            {/*<FormControlLabel*/}
            {/*  value="cash"*/}
            {/*  disabled={isDisabled}*/}
            {/*  control={<Radio color="primary" />}*/}
            {/*  label="CASH"*/}
            {/*/>*/}

            {pushToCardAllowed && (
              <FormControlLabel
                value="pushToCard"
                disabled={isDisabled}
                control={<Radio color="primary" />}
                label="Pay Out to Card"
              />
            )}
          </RadioGroup>

          {userData.method === 'pushToCard' && (
            <div>
              Only Visa Debit cards are acceptable, maximum request per withdrawal is $500USD
            </div>
          )}
        </Grid>
      </Grid>

      <PrepaidCardModal />

      {renderButtons()}
    </form>
  );
}
